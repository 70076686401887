<template>
  <div>
    <v-container>
      <p class="mb-4">
        Four standard sucrose solutions (10 mL each) are made by adding the masses shown in the
        table below. Determine the concentration of each solution in molarity. Note: sucrose FW:
        342.3 g/mol.
      </p>

      <v-simple-table style="max-width: 413px" class="mb-5 pl-6">
        <thead>
          <tr>
            <td style="text-align: left; font-weight: bold">Sucrose mass (g)</td>
            <td style="text-align: center; font-weight: bold">Concentration (M)</td>
          </tr>
        </thead>
        <tbody>
          <tr v-for="question in questions" :key="question.value">
            <td>
              <stemble-latex :content="question.part" /> <number-value :value="question.text" />
            </td>
            <td>
              <calculation-input
                v-model="inputs[question.value]"
                dense
                class="centered-input my-2"
              />
            </td>
          </tr>
        </tbody>
      </v-simple-table>

      <p>
        Note that the significant figures of the volume (10 mL) has been intentionally left
        ambiguous.
        <i>What experimental / procedural choice will determine the number significant figures? </i>
      </p>
    </v-container>
  </div>
</template>

<script>
import StembleLatex from '@/tasks/components/StembleLatex.vue';
import DynamicQuestionMixin from '@/tasks/mixins/dynamic-question';
import NumberValue from '@/tasks/components/NumberValue.vue';
import CalculationInput from '@/tasks/components/inputs/CalculationInput.vue';

export default {
  name: 'UCIrvineQuiz1S1Q5',
  components: {CalculationInput, NumberValue, StembleLatex},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        answer1: null,
        answer2: null,
        answer3: null,
        answer4: null,
      },
    };
  },
  computed: {
    sugarTrial1() {
      return this.taskState.getValueBySymbol('sugarTrial1').content;
    },
    sugarTrial2() {
      return this.taskState.getValueBySymbol('sugarTrial2').content;
    },
    sugarTrial3() {
      return this.taskState.getValueBySymbol('sugarTrial3').content;
    },
    sugarTrial4() {
      return this.taskState.getValueBySymbol('sugarTrial4').content;
    },
    questions() {
      return [
        {
          text: this.sugarTrial1,
          value: 'answer1',
          part: '$\\text{a)}$',
        },
        {
          text: this.sugarTrial2,
          value: 'answer2',
          part: '$\\text{b)}$',
        },
        {
          text: this.sugarTrial3,
          value: 'answer3',
          part: '$\\text{c)}$',
        },
        {
          text: this.sugarTrial4,
          value: 'answer4',
          part: '$\\text{d)}$',
        },
      ];
    },
  },
};
</script>
